<template>
  <div>
    <v-sheet>
      <v-card>
        <v-card-title>Турнирная таблица</v-card-title>
      </v-card>
      <v-simple-table class="mе-4 mb-4 ml-0 mr-0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Место</th>
              <th class="text-left">Команда</th>
              <th class="text-left">Раунд 1</th>
              <th class="text-left">Раунд 2</th>
              <th class="text-left">Финал</th>
              <th class="text-left">Итого</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in teams" :key="item.id">
              <td>{{ item.index }}</td>
              <td style="text-transform: capitalize">{{ item.id }}</td>
              <td>{{ item.first }}</td>
              <td>{{ item.second }}</td>
              <td>{{ item.final }}</td>
              <td>{{ item.total }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Picasso3DSStandings",
  props: ["items", "my-points", "my-game-id"],
  computed: {
    teams() {
      const teamsByIDs = _.reduce(
        this.items,
        (acc, item) => {
          const { tags } = item;
          const teamTag = _.find(tags, (tag) => /команда\s+\d+/gi.test(tag));
          if (teamTag) {
            if (acc[teamTag] == null) {
              acc[teamTag] = {
                items: [],
                quest: 0,
              };
            }
            acc[teamTag].items.push(item);
            acc[teamTag].quest += item.points;
          }
          return acc;
        },
        {}
      );
      const result = _.map(
        _.orderBy(
          Object.keys(teamsByIDs).map((teamKey) => {
            const { items: teamPlayers } = teamsByIDs[teamKey];
            const calcTeamPointsPerRound = (roundProperty) => {
              const sortedPlayers = _.orderBy(
                teamPlayers,
                [roundProperty],
                ["desc"]
              );
              const countablePlayers =
                sortedPlayers.length > 1
                  ? _.slice(sortedPlayers, 0, sortedPlayers.length - 1)
                  : sortedPlayers;
              const totalCountableQuestPoints = countablePlayers.reduce(
                (acc, player) => {
                  return acc + player[roundProperty];
                },
                0
              );
              const result =
                countablePlayers.length > 0
                  ? Math.round(
                      totalCountableQuestPoints / countablePlayers.length
                    )
                  : 0;
              return result;
            };
            const first = calcTeamPointsPerRound("first");
            const second = calcTeamPointsPerRound("second");
            const final = calcTeamPointsPerRound("final");
            const result = {
              id: teamKey,
              first,
              second,
              final,
              total: first + second + final, // Сумма всех раундов
            };
            return result;
          }),
          ["total"],
          ["desc"]
        ),
        (item, index) => {
          return {
            ...item,
            index: index + 1,
          };
        }
      );
      return result;
    },
  },
};
</script>
